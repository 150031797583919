var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._g({staticClass:"rounded overflow-hidden table",attrs:{"headers":_vm.translatedTableHeaders,"items":_vm.adItems,"loading":_vm.isLoading,"options":_vm.options,"hide-default-header":_vm.isMobile,"server-items-length":_vm.adsTotal,"hide-default-footer":""},scopedSlots:_vm._u([(_vm.isMobile)?{key:"top",fn:function(){return [_c('v-slide-y-transition',{attrs:{"mode":"out-in","hide-on-leave":true}},[_c('div',{staticClass:"secondary-lighten pb-4"},[_c('div',{staticClass:"d-flex align-center white justify-center py-4 rounded"},[_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{staticClass:"mr-2",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('c-btn',_vm._g(_vm._b({staticClass:"secondary rounded",attrs:{"icon":"","small":"","loading":_vm.isLoading,"icon-props":{
                    icon: '$update',
                    size: '16',
                    color: 'primary'
                  }},on:{"click":function($event){return _vm.$emit('fetch')}}},'c-btn',attrs,false),on))]}}],null,false,102111885)},[_c('span',[_vm._v(_vm._s(_vm.$t('ads.refresh')))])])],1),_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('c-btn',_vm._g(_vm._b({staticClass:"secondary rounded",attrs:{"icon":"","small":"","loading":_vm.isLoading,"icon-props":{
                    icon: '$reset-filters',
                    size: '16',
                    color: 'error'
                  }},on:{"click":function($event){return _vm.$emit('reset')}}},'c-btn',attrs,false),on))]}}],null,false,2803070890)},[_c('span',[_vm._v(_vm._s(_vm.$t('ads.reset')))])])],1)])])])]},proxy:true}:null,{key:"item.name",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column align-start"},[_c('span',{staticClass:"secondary-darken--text text-caption"},[_vm._v("#"+_vm._s(_vm.formatNumber(item.id)))]),_c('router-link',{class:{ 'pl-4': _vm.isMobile },attrs:{"to":{ name: 'Ad', params: { id: item.id } }}},[_c('span',{staticClass:"global-line-clamp"},[_vm._v(" "+_vm._s(item.name)+" ")])])],1)]}},(!_vm.isMobile)?{key:"header",fn:function({ props }){return [_c('tr',_vm._l((props.headers),function(header,index){return _c('th',{key:index,style:({
          height: 0,
          'min-width': header.minWidth
        })})}),0)]}}:{key:"item",fn:function({ item }){return [_c('tr',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{staticClass:"fill-height",attrs:{"loading":_vm.isLoading,"type":"card"}},[_c('ad-card',{staticClass:"fill-height",attrs:{"ad":item,"is-card-view":""},on:{"removed-handler":function($event){return _vm.$emit('removed-handler')}}})],1)],1)],1)],1)]}},{key:"item.status",fn:function({ item }){return [_c('custom-chip-status',{attrs:{"small":"","label":"","text-color":"black","with-icon":"","value":item.status,"settings":_vm.statusTypes}})]}},{key:"item.adFormatType",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.getFormatName(item.adFormatType))+" ")]}},{key:"item.approvalStatus",fn:function({ item }){return [_c('custom-chip-status',{staticClass:"font-weight-medium",attrs:{"small":"","label":"","value":item.latestApproval.status,"settings":_vm.adsApprovalStatusTypes}})]}},{key:"item.actions",fn:function({ item }){return [_c('ads-actions',{attrs:{"ad":item},on:{"removed":function($event){return _vm.$emit('removed-handler')}}})]}}],null,true)},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }