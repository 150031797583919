<template>
  <v-card elevation="0">
    <v-card-title
      class="d-flex align-center justify-space-between title white--text black py-3 px-sm-3 px-lg-5"
    >
      <div class="d-flex align-center">
        <span class="text-subtitle-1 custom-title font-weight-medium">{{ $t('ads.ads') }}</span>
        <view-switcher
          v-if="!isMobile"
          class="font-weight-light text-no-wrap ml-5 mt-0 pt-0"
          :view-type-is-cards="viewTypeIsCards"
          @change="changeViewTypeIsCards($event)"
        />
      </div>
      <c-btn
        :id="seleniumIds.SELENIUM_TEST_ADS_CREATE_ADS"
        color="primary"
        :height="32"
        :to="createAdRoute()"
      >
        <span class="font-weight-regular">{{ computedBtnLabel }}</span>
      </c-btn>
    </v-card-title>

    <v-card-text class="secondary-lighten pa-0">
      <div class="d-flex flex-column white pl-4 pl-sm-3 pb-3 pb-sm-5 pa-5 pr-0">
        <v-tabs
          v-if="filterAdFormatsIsShown && !isMobile"
          show-arrows
          prev-icon="$arrow-left"
          next-icon="$arrow-right"
          slider-color="primary lighten-2"
          grow
          class="tabs"
        >
          <c-btn
            v-for="(tab, index) in adFormatTypesForTab"
            :key="index"
            depressed
            :color="currentTab === index ? 'primary' : ''"
            :class="['text-capitalize px-0 justify-center custom-button black--text mr-1 px-3', { 'white--text': currentTab === index }]"
            :label="tab.label"
            @click="changeCurrentTab(index)"
          />
        </v-tabs>
        <div class="d-flex flex-column-reverse align-center align-sm-start flex-wrap flex-sm-nowrap flex-sm-row justify-space-between black--text mt-sm-5 pr-4 pr-sm-5 pr-md-3">
          <div class="d-flex flex-column flex-wrap flex-sm-nowrap flex-lg-row align-start align-lg-center global-full-width ">
            <c-text-field
              :value="filters.commonSearch"
              solo
              flat
              :class="['width-223 mr-2 flex-grow-1 flex-shrink-0 pr-sm-12 pr-lg-0', { 'global-full-width': isMdAndDown }]"
              :placeholder="$t('ads.enter_name')"
              hide-details
              background-color="white"
              border-color="secondary"
              :height="isMobile ? 46 : 28"
              @input="setFilters({ name: 'commonSearch', event: $event })"
            >
              <template #prepend-inner>
                <v-icon :color="isMobile ? 'black' : 'secondary-darken'" :size="16">
                  $magnifier
                </v-icon>
              </template>
            </c-text-field>
            <ad-filters
              class="global-full-width"
              :is-loading="isLoading"
              :options="options"
              :filters="filters"
              :headers="headers"
              :view-type-is-cards="viewTypeIsCards"
              @change-filters="changeFilters($event)"
              @change-sort-by="changeSortBy($event, 'sortBy')"
              @change-sort-desc="changeSortBy($event, 'sortDesc')"
            />
          </div>
          <div class="d-flex align-start align-md-center mb-5 mb-lg-0">
            <div class="mr-1">
              <v-tooltip bottom class="mr-1">
                <template #activator="{ on, attrs }">
                  <c-btn
                    v-bind="attrs"
                    icon
                    :small="!isMobile"
                    class="secondary-lighten rounded"
                    :loading="isLoading"
                    :width="isMobile ? 36 : 26"
                    :height="isMobile ? 36 : 26"
                    :icon-props="{
                      icon: '$update',
                      size: isMobile ? '20' : '14',
                      color: 'primary'
                    }"
                    v-on="on"
                    @click="debouncedFetchAds()"
                  />
                </template>
                <span>{{ $t('ads.refresh') }}</span>
              </v-tooltip>
            </div>
            <div class="mr-1">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <c-btn
                    v-bind="attrs"
                    icon
                    :small="!isMobile"
                    class="secondary-lighten rounded"
                    :loading="isLoading"
                    :width="isMobile ? 36 : 26"
                    :height="isMobile ? 36 : 26"
                    :icon-props="{
                      icon: '$reset-filters',
                      size: isMobile ? '20' : '14',
                      color: 'error'
                    }"
                    v-on="on"
                    @click="resetFilters()"
                  />
                </template>
                <span>{{ $t('ads.reset') }}</span>
              </v-tooltip>
            </div>
            <shown-headers
              v-if="!isMobile"
              :headers="headersComputed"
              local-key="ads"
              @headers-updated="setComputedHeaders($event)"
            />
          </div>
        </div>
      </div>

      <ads-view
        :options="options"
        :page-count="options.pageCount"
        :items="adItems"
        :is-loading="isLoading"
        :filters="filters"
        :headers="headers"
        class="mt-3"
        :view-type-is-cards="viewTypeIsCards"
        :ad-format-tab-filter="adFormatTabFilter"
        @change-filters="changeFilters($event)"
        @change-tab="changeAdFormatTabFilter($event)"
        @change-options="changeOptions($event)"
        @fetch="debouncedFetchAds()"
        @reset-filters="resetFilters()"
      />
      <c-dialog
        v-if="createdAd"
        :value="dialogIsShown"
        max-width="800"
        :title="$t('ads.created_ad_modal_title')"
        @input="closeDialog()"
      >
        <div class="d-flex justify-center">
          {{ $t('ads.created_ad_modal_body', { name: adNetwork.name }) }}
        </div>
        <template #actions>
          <v-container fluid class="px-0">
            <v-row no-gutters>
              <v-col cols="12" md="4" class="py-1 px-0">
                <c-btn
                  block
                  depressed
                  color="primary"
                  :label="$t('ads.start_another_campaign')"
                  @click="redirectCreateCampaign()"
                />
              </v-col>
              <v-col cols="12" md="4" class="py-1 px-0 pl-md-2">
                <c-btn
                  block
                  depressed
                  color="primary"
                  :label="$t('ads.create_more_ads')"
                  @click="redirectCreateAd()"
                />
              </v-col>
              <v-col cols="12" md="4" class="py-1 px-0 pl-md-2">
                <c-btn
                  block
                  depressed
                  color="primary"
                  :label="$t('ads.top_up_the_balance')"
                  @click="redirectAddFunds()"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </c-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CDialog from '@clickadilla/components/ui/CDialog.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'
  import seleniumDynamicFields from '@clickadilla/components/utils/selenium-dynamic-field.js'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import { datatableFormater } from '@clickadilla/datatable-formatter'
  import debounce from 'debounce'
  import AdsView from './AdsView/Index.vue'
  import ViewSwitcher from './ViewSwitcher.vue'
  import routeNames from '@/types/route-names.js'
  import gtmPush from '@/services/utils/gtm-push.js'
  import Ads from '@/services/classes/Ads.js'
  import adsRepository from '@/services/repositories/ads-repository.js'
  import handleErrors from '@/services/handleErrors.js'
  import AdFilters from '@/views/Ads/AdFilters.vue'
  import ShownHeaders from '@/components/ShownHeaders.vue'

  export default {
    name: 'Ads',
    components: {
      ShownHeaders,
      CTextField,
      AdFilters,
      ViewSwitcher,
      AdsView,
      CBtn,
      CDialog
    },
    data() {
      return {
        routeNames,
        viewTypes: {
          cards: 'cards',
          table: 'table'
        },
        viewType: '',
        localStorageViewType: 'adViewType',
        currentTab: 0,
        adFormatTabFilter: {
          key: 'adFormatType',
          value: ''
        },
        isLoading: false,
        adItems: [],
        headers: [],
        filters: {
          id: '',
          name: '',
          adFormatType: '',
          status: '',
          approvalStatus: '',
          commonSearch: ''
        },
        options: {
          sortBy: [],
          sortDesc: [true],
          page: 1,
          itemsPerPage: 15,
          pageCount: 0
        },
        debouncedFetchAds: null,
        statusTypes: Ads.getStatusTypes(),
        createdAd: null,
        dialogIsShown: false,
        seleniumIds
      }
    },
    computed: {
      ...mapState('settings', ['adNetwork']),
      ...mapGetters('settings', ['getAdFormatTypesByAdNetwork']),
      ...mapGetters('approvables', ['approvalStatusTypes']),
      adFormatTypesWithLabel() {
        const adFormatTypesWithLabel = this.getAdFormatTypesByAdNetwork.map((type) => {
          const labelValue = type === adFormats.IN_PAGE_AD && this.adNetwork.settings.renameInPageFormat ? 'tg_interstitial' : type
          return {
            value: type,
            label: this.$t(`main.ad_format_types.${labelValue}`)
          }
        })

        const allAdFormats = { value: '', label: this.$t('ads.all_formats') }
        return [allAdFormats, ...adFormatTypesWithLabel]
      },
      approvalStatusesWithLabel() {
        const approvalStatusTypes = this.approvalStatusTypes.map((status) => ({
          ...status,
          label: this.$t(status.label)
        }))
        const allAdFormats = { value: '', label: this.$t('ads.all_approval_statuses') }
        return [allAdFormats, ...approvalStatusTypes]
      },
      headersComputed: {
        get() {
          return [
            {
              value: 'name',
              text: this.$t('main.name'),
              searchable: true,
              filterPlaceholder: this.$t('ads.search_name'),
              class: 'black--text',
              width: 208
            },
            {
              value: 'actions',
              text: this.$t('main.actions'),
              sortable: false,
              width: 140,
              class: 'black--text'
            },
            {
              value: 'adFormatType',
              text: this.$t('main.ad_format'),
              filters: true,
              filterPlaceholder: this.$t('ads.search_ad_format'),
              settingItems: this.adFormatTypesWithLabel,
              class: 'black--text',
              width: 140,
              sortable: false
            },
            {
              value: 'status',
              text: this.$t('main.status'),
              filters: true,
              filterPlaceholder: this.$t('ads.search_status'),
              settingItems: this.statusTypes,
              class: 'black--text',
              width: 132
            },
            {
              value: 'approvalStatus',
              text: this.$t('main.approval_status'),
              name: 'latestApproval.status',
              filters: true,
              filterPlaceholder: this.$t('ads.search_approval'),
              settingItems: this.approvalStatusesWithLabel,
              class: 'black--text',
              sortable: false
            }
          ]
        },
        set(value) {
          this.headers = [...value]
        }

      },
      isMdAndDown() {
        return this.$vuetify.breakpoint.mdAndDown
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      viewTypeIsCards() {
        return this.viewType === this.viewTypes.cards
      },
      adFormatTypesForTab() {
        const adFormatTypesWithLabel = this.getAdFormatTypesByAdNetwork.map((type) => {
          const labelValue = type === adFormats.IN_PAGE_AD && this.adNetwork.settings.renameInPageFormat ? 'tg_interstitial' : type
          return {
            value: type,
            label: this.$t(`main.ad_format_types.${labelValue}`),
            id: seleniumDynamicFields(seleniumIds.SELENIUM_TEST_ADS_TAB_ITEM, type)
          }
        })
        const allAdFormats = { value: '', label: this.$t('ads.all'), id: seleniumIds.SELENIUM_TEST_ADS_TAB_ITEM_ALL }
        return [allAdFormats, ...adFormatTypesWithLabel]
      },
      computedBtnLabel() {
        return (
          {
            [adFormats.POPUNDER]: this.$t('ads.create_popunder'),
            [adFormats.BANNER]: this.$t('ads.create_banner'),
            [adFormats.NATIVE_AD]: this.$t('ads.create_native'),
            [adFormats.DIRECT_LINK]: this.$t('ads.create_direct_link'),
            [adFormats.IN_STREAM_AD]: this.$t('ads.create_instream'),
            [adFormats.GALLERY]: this.$t('ads.create_gallery_ad'),
            [adFormats.INTERSTITIAL]: this.$t('ads.create_interstitial'),
            [adFormats.IN_PAGE_AD]: this.adNetwork.settings.renameInPageFormat
              ? this.$t('ads.create_tg_interstitial')
              : this.$t('ads.create_in_page_ad'),
            [adFormats.WEB_PUSH]: this.$t('ads.create_push'),
            [adFormats.IOS_CALENDAR]: this.$t('ads.create_ios_calendar')
          }[this.adFormatTabFilter.value] || this.$t('ads.create_ads')
        )
      },
      filterAdFormatsIsShown() {
        return this.adFormatTypesForTab.length > 2
      }
    },
    created() {
      this.headers = [...this.headersComputed]
      this.viewType = this.isMobile ? this.viewTypes.cards : (localStorage[this.localStorageViewType] || this.viewTypes.table)
      this.createdAd = this.$route.params.createdAd ?? null

      this.debouncedFetchAds = debounce(() => {
        this.fetchAds()
      }, 500)

      if (this.createdAd) {
        this.dialogIsShown = true
        gtmPush({ event: GTM_EVENTS.USER_FORK_SHOW_BANNER })
      }
    },
    methods: {
      seleniumDynamicFields,
      createAdRoute() {
        if (this.adFormatTabFilter.value) {
          return { name: 'CreateAd', query: { adFormat: this.adFormatTabFilter.value } }
        }
        return { name: 'CreateAd' }
      },
      changeOptions(options) {
        this.options = options
        this.debouncedFetchAds()
      },
      changeSortBy(val, key) {
        this.options[key] = val
        if (key !== 'page') {
          this.options.page = 1
        }
        this.debouncedFetchAds()
      },
      setFilters({ name, event }) {
        this.filters[name] = event
        this.debouncedFetchAds()
      },
      changeFilters(filters) {
        this.filters = filters
        this.options.page = 1
        this.currentTab = this.adFormatTypesForTab.findIndex(({ value }) => value === this.filters.adFormatType)
        this.debouncedFetchAds()
      },
      resetFilters() {
        Object.keys(this.filters).forEach((key) => {
          this.filters[key] = ''
        })
        this.resetTable()
      },
      resetTable() {
        this.options.sortBy = []
        this.options.sortDesc = [true]
        this.options.page = 1
        this.debouncedFetchAds()
      },
      async fetchAds() {
        this.isLoading = true

        const defaultSort = this.options.sortBy.length === 0 ? ['id'] : this.options.sortBy

        const headers = [{
          value: 'id',
          data: 'id',
          searchable: true
        }, ...this.headers]

        const params = datatableFormater({
          headers,
          headersDictionary: Ads.getDictionaryForTable(),
          search: this.filters,
          options: { ...this.options, sortBy: defaultSort }
        })
        try {
          const response = await adsRepository.table(params)
          this.adItems = response.data.map((ad) => new Ads(ad))
          this.options.pageCount = Math.ceil(response.recordsFiltered / this.options.itemsPerPage)
        } catch (error) {
          handleErrors(error)
        }
        this.isLoading = false
      },
      changeCurrentTab(index) {
        this.adFormatTabFilter = {
          key: 'adFormatType',
          value: this.adFormatTypesForTab[index].value
        }
      },
      setComputedHeaders(computedHeaders) {
        this.headers = [...computedHeaders]
      },
      changeAdFormatTabFilter(filter) {
        this.adFormatTabFilter = filter
        this.currentTab = this.adFormatTypesForTab.findIndex(({ value }) => value === filter.value)
      },
      changeViewTypeIsCards(typeIsCards) {
        this.viewType = typeIsCards ? this.viewTypes.cards : this.viewTypes.table
        localStorage[this.localStorageViewType] = this.viewType
      },
      closeDialog() {
        this.dialogIsShown = false
        gtmPush({ event: GTM_EVENTS.USER_FORK_SKIP_BANNER })
      },
      redirectAddFunds() {
        gtmPush({ event: GTM_EVENTS.USER_FORK_BUTTON_ADD_FUNDS })
        this.$router.push({ name: routeNames.ADD_FUNDS })
      },
      redirectCreateCampaign() {
        gtmPush({ event: GTM_EVENTS.USER_FORK_BUTTON_CREATE_CAMPAIGN })
        this.$router.push({ name: routeNames.CREATE_CAMPAIGN })
      },
      redirectCreateAd() {
        gtmPush({ event: GTM_EVENTS.USER_FORK_BUTTON_CREATE_AD })
        this.$router.push({ name: routeNames.CREATE_AD, query: { adFormat: this.createdAd.adFormatType } })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .v-application .custom-tab {
    background-color: transparent !important;
    color: var(--v-white-base);
    padding: 0;
    min-width: 0;
  }

  ::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 28px;
  }

  .tabs ::v-deep {
    .v-tab{
      border:none!important;
      min-width: auto;
    }
    & .v-slide-group__prev, .v-slide-group__next {
      background-color: transparent;
      & .v-icon{
        color: var(--v-primary-base);
      }
    }
  }

  .width-223 {
    width: 223px;
  }

  ::v-deep .custom-button {
    height: 32px !important;
    min-width: max-content !important;
    padding: 0 12px !important;
  }

  ::v-deep .v-tabs-bar {
    height: auto !important;
  }

  .custom-title {
    font-size: 16px !important;
    line-height: 22px !important;

    @media screen and (min-width: 600px) {
      line-height: 16px !important;
    }

    @media screen and (min-width: 1440px) {
      font-size: 20px !important;
      line-height: 20px !important;
    }
  }

  ::v-deep .v-slide-group__next,
  ::v-deep .v-slide-group__prev {
    min-width: 20px;
    max-width: 20px;

    @media screen and (max-width: 580px) {
      min-width: 0;
      max-width: 0;
    }

    & .v-icon {
      color: var(--v-white-base);

      @media screen and (max-width: 580px) {
        display: none;
      }
    }
  }

</style>
