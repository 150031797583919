<template>
  <v-card elevation="0" :class="['d-flex flex-column pa-3', { 'box-shadow': isCardView }]">
    <v-card-title v-if="!isCardView" class="pa-0 pt-2 pl-1 mb-5 text-h6 font-weight-medium black--text">
      {{ $t('ads.campaign_ad') }}
    </v-card-title>
    <component
      :is="creativeComponents"
      :class="['mb-2', { 'height-190': !isPreviewExpanded }]"
      :ad="ad"
      @expand="isPreviewExpanded = $event"
    />
    <v-data-table
      class="mb-3"
      :headers="headers"
      :items="items"
      mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      hide-default-header
    >
      <template #item.name="{ item }">
        <span class="text-caption secondary-darken--text">{{ item.name }}</span>
      </template>

      <template #item.result="{ item }">
        <router-link
          v-if="item.id"
          :to="{ name: 'Ad', params: { id: item.id } }"
          class="font-weight-bold"
        >
          #{{ ad.id }}
        </router-link>
        <src-outer-link v-if="item.link" class="overflow-hidden" :src="item.link" />

        <custom-chip-status
          v-else-if="item.chip"
          class="font-weight-medium"
          :value="item.chip.value"
          :settings="item.chip.settings"
          :with-icon="item.chip.withIcon"
          :text-color="item.chip.textColor"
          small
          label
        />

        <template v-else-if="item.list">
          <ul class="pl-0">
            <li
              v-for="(scanReport, index) in item.list.scanReport"
              :key="index"
              class="d-flex justify-end"
            >
              <a :href="scanReport" target="_blank">
                {{ item.list.title }}
              </a>
            </li>
          </ul>
        </template>

        <span v-else>
          <span class="font-weight-bold black--text">{{ item.result }}</span>
        </span>
      </template>
    </v-data-table>
    <rotation-progress v-if="rotationTypeIsSmart" class="mt-n3 mb-7" :rotation="rotation" />
    <v-spacer />
    <ads-actions v-if="isCardView" class="actions pa-3 border-secondary rounded d-flex justify-center" :ad="ad" @removed="$emit('removed-handler')" />
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { rotationsTypes } from '@clickadilla/components/constants/rotations-types.js'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import { adTypes } from '@clickadilla/components/constants/ad-types.js'
  import routeNames from '@/types/route-names.js'
  import Ads from '@/services/classes/Ads.js'
  import AdsActions from '@/components/ads/AdsActions.vue'
  import SrcOuterLink from '@/components/ads/SrcOuterLink.vue'
  import DirectLinkIcon from '@/components/DirectLinkIcon.vue'
  import CustomChipStatus from '@/components/CustomChipStatus.vue'
  import RotationProgress from '@/components/ads/AdCard/RotationProgress.vue'

  export default {
    name: 'AdCard',
    components: {
      CustomChipStatus,
      DirectLinkIcon,
      SrcOuterLink,
      AdsActions,
      RotationProgress
    },
    props: {
      ad: {
        type: Ads,
        required: true
      },
      isCardView: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        routeNames,
        statusTypes: Ads.getStatusTypes(),
        isPreviewExpanded: false
      }
    },
    computed: {
      ...mapGetters('approvables', ['adsApprovalStatusTypes']),
      ...mapState('settings', ['adNetwork']),
      creativeComponents() {
        return {
          [adFormats.BANNER]: () => (this.ad.model.type === adTypes.SELFCODE ? '' : import('./BannerCreativePreview.vue')),
          [adFormats.WEB_PUSH]: () => import('./WebPushCreativePreview.vue'),
          [adFormats.IN_STREAM_AD]: () => import('./InStreamCreativePreview.vue'),
          [adFormats.NATIVE_AD]: () => import('./NativeAdCreativePreview.vue'),
          [adFormats.DIRECT_LINK]: () => import('./DirectLinkCreativePreview.vue'),
          [adFormats.INTERSTITIAL]: () => import('./InterstitialCreativePreview.vue'),
          [adFormats.IN_PAGE_AD]: () => import('./InPageCreativePreview.vue')
        }[this.ad.adFormatType]
      },
      rotation() {
        return this.ad.model.rotation ?? {}
      },
      rotationTypeIsSmart() {
        return this.rotation.type === rotationsTypes.SMART
      },
      headers() {
        return [
          { value: 'name', align: 'start', class: 'my-style' },
          { value: 'result', align: 'end' }
        ]
      },
      items() {
        const formatName = this.ad.adFormatType === adFormats.IN_PAGE_AD && this.adNetwork.settings.renameInPageFormat
          ? 'tg_interstitial'
          : this.ad.adFormatType
        const items = [
          { name: 'ID', id: this.ad.id, isShown: !!this.ad.id },
          { name: 'Format', result: this.$t(`main.ad_format_types.${formatName}`), isShown: !!this.ad.adFormatType },
          { name: 'Name', result: this.ad.name, isShown: !!this.ad.name },
          {
            name: this.$t('ads.content_feed_name'),
            result: this.ad.model.contentFeedName,
            isShown: !!this.ad.model.contentFeedName
          },
          {
            name: this.$t('ads.content_feed_url'),
            link: this.ad.model.contentFeedUrl,
            isShown: !!this.ad.model.contentFeedUrl
          },
          {
            name: this.$t('ads.provider_website'),
            link: this.ad.model.providerWebsite,
            isShown: !!this.ad.model.providerWebsite
          },
          {
            name: this.$t('ads.type'),
            result: this.$t(`main.rotation_types.${this.ad.model.type}`),
            isShown: !!this.ad.model.type
          },
          {
            name: 'URL',
            link: this.ad.model.src,
            isShown: !!this.ad.model.src
          },
          {
            name: this.$t('ads.title'),
            result: this.ad.model.title,
            isShown: !!this.ad.model.title
          },
          {
            name: this.$t('ads.created_at'),
            result: this.ad.createdAt
          },
          {
            name: this.$t('ads.skip_time'),
            result: `${this.ad.model.skipTime} ${this.$t('ads.seconds')}`,
            isShown: !!this.ad.model.skipTime
          },
          {
            name: this.$t('main.status'),
            chip: {
              value: this.ad.status, settings: this.statusTypes, withIcon: true, textColor: 'black'
            }
          },
          {
            name: this.$t('main.approval_status'),
            chip: { value: this.ad.latestApproval.status, settings: this.adsApprovalStatusTypes, withIcon: false }
          },
          {
            name: this.$t('ads.reject_cause'),
            result: this.ad.latestApproval.rejectCause.details,
            isShown: !!this.ad.latestApproval.rejectCause.details
          },
          {
            name: this.$t('ads.cause_details'),
            list: { scanReport: this.ad.scanReports, title: this.$t('ads.open') },
            isShown: !!window.localStorage.getItem('isBeta')
          },
          {
            name: this.$t('ads.impressions_threshold'),
            result: this.ad.model.rotation?.impressionsThreshold,
            isShown: !!this.ad.model.rotation?.impressionsThreshold
          },
          {
            name: this.$t('ads.hours_threshold'),
            result: this.ad.model.rotation?.hoursThreshold,
            isShown: !!this.ad.model.rotation?.hoursThreshold
          },
          {
            name: this.$t('ads.rotation_type'),
            result: this.ad.model.rotation?.type,
            isShown: !!this.ad.model.rotation?.type
          },
          {
            name: this.$t('ads.size'),
            result: this.ad.size,
            isShown: !!this.ad.size
          },
          {
            name: this.$t('ads.common_url'),
            link: this.ad.commonUrl,
            isShown: !!this.ad.commonUrl
          },
          {
            name: this.$t('ads.smart_rotation'),
            result: this.rotationTypeIsSmart ? this.$t('ads.on') : this.$t('ads.off'),
            isShown: this.rotation.smartProgress || this.rotation.smartProgress === 0
          }
        ]
        return items.filter(({ isShown }) => isShown !== false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  :deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 36px;
    padding: 5px 0;
  }
  :deep .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
    background-color: var(--v-white-base) !important;
  }
  .box-shadow {
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.08) !important;
  }
  .height-190 {
    height: 190px;
  }
  .actions {
    gap: 20px;
  }
</style>
